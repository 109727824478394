<template>
    <div>
        <div class="d-flex align-items-center justify-content-center" style="height: 90vh;">

            <div class="login-wrapper wd-300 wd-xs-350 pd-25 pd-xs-40 bg-br-primary rounded bd bd-white-1">
                <div class="tx-center mg-b-20">VARS 2 FACTOR AUTHENTICATION</div>
                <p>Please enter your One Time Password</p>

                <!--                <div class="text-danger mg-b-10" vars-id="error">No Errors Sir!</div>-->
                <div class="form-group">
                    <input type="text" v-on:keyup.enter="auth" v-model="otp" maxlength="6" class="form-control form-control-dark">
                </div>

                <p class="text-danger" v-if="error">Failed to authenticate.</p>

                <submit-button @click="auth" :background="'btn-info'" :text="'Authenticate'" :clicked="waiting" />

            </div><!-- login-wrapper -->
        </div><!-- d-flex -->
    </div>
</template>

<script>
import axios from 'axios';
import SubmitButton from '../../../components/SubmitButton.vue';
export default {
    name: "Authenticate",
    components: {SubmitButton},
    props: {
        route: String,
    },
    data(){
        return {
            otp: null,
            error: false,
            waiting: false,
        }
    },
    methods: {
        auth(){
            this.error = false;
            this.waiting = true;
            axios.post(`${process.env.VUE_APP_API_URL}/v1/auth/2fa`, {
                otp: this.otp
            })
                .then(() => {
                    this.waiting = false;
                    this.$success("Successfully validated your 2FA");
                    if(this.route !== null && this.route !== undefined){
                        this.$router.push({name: this.route});
                    }else{
                        this.$router.push({name: 'Dashboard'});
                    }
                })
                .catch(() => {
                    this.$error("Failed to validate your 2FA");
                    this.error = true;
                    this.waiting = false;
                });
        }
    }
}
</script>